import logos from "../../asset/Group 1330.png";
import logo from "../../asset/Group 1332.png";
import logoss from "../../asset/Group1334.png";

export const SeeUsData = [
  {
    title: "Cross Functional Experts",
    description:
      "Our founding team, comprised of IIT/IIM graduates and CFA charter holders, brings together a collective 60 years of professional & global leadership expertise in investment, finance, technology, and customer experience.",
    image: logos,
  },
  {
    title: "A la carte Advisory",
    description:
      "At Elever, we design investment portfolios to match each client's specific needs, goals, risk profile, and return expectations. Our continuous advisory process includes regular adjustments to adapt to market changes and client requirements.",
    image: logo,
  },
  {
    title: "All-in-One Platform",
    description:
      "Experience significant cost and time savings with our secure & robust tech framework, seamlessly integrating client requirements, investment advice, execution, and transparent reporting - all under a single platform.",
    image: logoss,
  },
];
