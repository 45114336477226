import React, { useEffect } from "react";
import HeroSection from "./HeroSection";

import PartnerSection from "./PartnerSection";
import CaseStudySection2 from "./CaseStudySection2";

const Index = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <HeroSection />
      {/* <CaseStudySection /> */}
    </>
  );
};

export default Index;
