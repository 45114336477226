import React from "react";
import twitter from "../../asset/twitter.png";
import facebook from "../../asset/facebook.png";
import youtube from "../../asset/youtube.png";
import linkdin from "../../asset/linkdin.png";
import instagram from "../../asset/instagram.png";

const Social = () => {
  return (
    <>
      <div className="border-[1px] border-gray-700 sm:w-[80%]  w-full mx-auto mb-2 "></div>
      <div className="  w-full mx-auto sm:w-[80%]  sm:mx-auto  sm:flex pb-6 ">
        <div className=" leading-loose text-sm h-[30vh] sm:h-0 sm:flex  sm:col-span-4   sm:mt-2 ">
          <p className="text-center ">
            @2023 Elever Investment adviser private Limited, All rights
            reserved.
          </p>
          <ul className="text-center  sm:ps-6 flex justify-center gap-5 ">
            <li className="font-medium">
              <a href="https://www.elever.app/privacy-policy">Privacy</a>
            </li>
            <li className="font-medium">
              <a href="https://www.elever.app/terms-and-conditions">Terms</a>
            </li>
            <li className="font-medium">
              <a href="https://www.elever.app/disclosure">Disclosure</a>
            </li>
            <li className="font-medium contents">
              <a href="https://www.elever.app/investor-charter">
                Investor Charter
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Social;
