import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  Corporate: true,
  Retail: false,
  White: false,
  scroll: false,
};

export const tabsSlice = createSlice({
  name: "Tabs",
  initialState,
  reducers: {
    tabsFunc: (state, action) => {
      const updatedState = { ...state };

      for (const key in updatedState) {
        updatedState[key] = false;
      }

      // Set the specified keys from action.payload to true
      for (const key in action.payload) {
        if (updatedState.hasOwnProperty(key)) {
          updatedState[key] = true;
        }
      }

      return updatedState;
    },
  },
});

export const { tabsFunc } = tabsSlice.actions;

export default tabsSlice.reducer;
