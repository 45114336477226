import React, { useState } from "react";
import carpGraph1 from "../../../asset/carpGraph1.png";
import carpCase1 from "../../../asset/carpCase1.png";
import carpCase2 from "../../../asset/carpCase2.png";
import carpCase3 from "../../../asset/carpCase3.png";
import carpCase4 from "../../../asset/carpCase4.png";
import carpAnd1 from "../../../asset/carpAnd1.png";
import carpAnd2 from "../../../asset/carpAnd2.png";
import carpAnd3 from "../../../asset/carpAnd3.png";
import carpBussVec from "../../../asset/carpBussVec.png";
import carp4 from "../../../asset/carp4.png";
import carp5 from "../../../asset/carp5.png";
import carVecBar from "../../../asset/carpVecBar.png";
import carpTas from "../../../asset/capTas.png";
import carpChartAn from "../../../asset/carpChartAn.png";
import carpChart from "../../../asset/carpChart.png";
import caseStudy1 from "../../../asset/caseStudy1.png";
import PartnerSection from "./PartnerSection";
import vector from "../../../asset/Vector.png";
import { Link } from "react-router-dom";

const CaseStudySection2 = ({ select }) => {
  const [button, setButton] = useState(0);
  // console.log(select, "select");

  return (
    <>
      {!select ? (
        <>
          <div className="w-full bg-body-color pb-6 pt-8 ">
            <div className="sm:w-[60%] w-full px-3 mx-auto ">
              <img
                src={carpGraph1}
                className="object-cover text-center "
                alt=""
              />
            </div>
            <div className=" sm:w-[80%] w-full   mx-auto my-16  ">
              <h1 className="text-[#6C297C] text-center sm:text-start font-bold sm:text-3xl text-xl ">
                Case Study
              </h1>
              <p className="tracking-wide font-medium text-sm sm:text-[15px] sm:block hidden">
                An early-learner school chain intends to retain their core
                teaching staff and has the below characteristics.
              </p>
              <div className="grid sm:grid-flow-row grid-cols-3  place-items-center ">
                <div className="sm:col-span-2 col-span-full">
                  <ul className="list-disc leading-loose py-3  px-8 text-sm text-gray-600 ">
                    <li>
                      Average monthly salary (Teacher + Caregiver only ) + Rs
                      28,000/-
                    </li>
                    <li>
                      Replacement cost per employee (recruitment + training &
                      development) assumed at an extremely conservative 40%
                    </li>
                    <li>Average annual increment is considered as 10%</li>
                    <li>
                      Average new hire at market rate considered a nominal 15%
                    </li>
                  </ul>
                </div>
                <div className="sm:col-span-1 col-span-full sm:-mt-14 order-first sm:order-last">
                  <img src={caseStudy1} className="object-cover w-80 " alt="" />
                </div>
              </div>
            </div>
            <div className=" justify-center w-[95%] hidden  items-center sm:gap-6 gap-2 text-center place-items-center h-40 mx-auto sm:w-full  ">
              <button
                className={`${
                  button === 0
                    ? "bg-[#1B8DB5] hover:bg-[#1B8DB5] text-white "
                    : " bg-gray-300 text-gray-500"
                }   sm:font-medium py-2.5 px-4 w-36 h-10   rounded inline-flex  text-sm sm:text-[17px]`}
                onClick={() => setButton(0)}
              >
                Case Study -1
              </button>
              <button
                className={`${
                  button === 1
                    ? "bg-[#1B8DB5] hover:bg-[#1B8DB5] text-white"
                    : " bg-gray-300 text-gray-500"
                }  sm:font-medium py-2.5 px-4  w-36 h-10   rounded inline-flex  text-sm sm:text-[17px] `}
                onClick={() => setButton(1)}
              >
                Case Study -1
              </button>
              <button
                className={`${
                  button === 2
                    ? "bg-[#1B8DB5] hover:bg-[#1B8DB5] text-white"
                    : " bg-gray-300 text-gray-500"
                } sm:font-medium py-2.5 px-4 w-36 h-10   rounded inline-flex  text-sm sm:text-[17px]`}
                onClick={() => setButton(2)}
              >
                Case Study -1
              </button>
            </div>

            <div className="w-[75%] mx-auto hidden sm:block ">
              <img
                src={carpCase4}
                className="object-cover text-center "
                alt=""
              />
              <h1 className="text-center pt-6 text-xl font-bold text-black opacity-60">
                Solution
              </h1>

              <img
                src={carpCase2}
                className="object-cover text-center my-6"
                alt=""
              />
              <img
                src={carpCase3}
                className="object-cover text-center pt-8"
                alt=""
              />
            </div>

            <div className="w-[90%] mx-auto sm:hidden block ">
              <img
                src={carpAnd1}
                className="object-cover text-center "
                alt=""
              />

              <img
                src={carpAnd2}
                className="object-cover text-center "
                alt=""
              />
              <img
                src={carpAnd3}
                className="object-cover text-center pt-6"
                alt=""
              />
            </div>
          </div>
          <PartnerSection />
          <div className=" justify-center items-center hidden sm:flex bg-body-color pb-10">
            <Link to="/white-label">
              <button className="uppercase bg-buttoncolor text-white font-medium text-sm tracking-wide px-8 py-2.5 rounded-full shadow-md shadow-buttoncolor">
                Lets talk
              </button>
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className="w-full bg-body-color py-16  ">
            <div className="bg-[#E1E3FC] py-10">
              <div className="sm:w-[60%] w-full px-3 mx-auto">
                <img
                  src={carp4}
                  className="object-cover text-center w-full"
                  alt="Car"
                />
              </div>
            </div>

            <div className="relative">
              <div className="my-10 sm:w-[70%] w-[90%]  mx-auto">
                <h1 className="font-bold sm:text-3xl text-xl capitalize  py-3  text-center text-[#6C297C]">
                  How can Elever's TAS help your business ?
                </h1>
                <div className="text-center">
                  <p className="leading-loose tracking-wide text-sm sm:text-[15px] font-medium ">
                    Normally, small and mid-sized businesses harbor
                    misconception that treasury management is relevant only for
                    large corporates and with their small or negative cash
                    flows, incremental benefits are too insignificant for the
                    effort. However, it is actually the other way around. With
                    no access to low-cost financing, these incremental gains are
                    way more valuable for MSMEs.
                  </p>
                  <p className="leading-loose tracking-wide my-5 text-sm sm:text-[15px] font-medium">
                    For example, consider an analytics company with a monthly
                    cash flow of INR 1.25 Crore which is used by the end of the
                    month.
                  </p>
                  <p className="leading-loose tracking-wide  text-sm sm:text-[15px] font-medium">
                    In this constrained scenario, TAS can generate additional
                    INR 9 Lakhs by the end of the year which would take care of
                    1-2 days in case payments are late from customers. For a
                    start-up with funding for a year, TAS has the potential to
                    generate nearly 1 month of additional working capital.
                  </p>
                  <p className="leading-loose tracking-wide my-5  text-sm sm:text-[15px] font-medium">
                    And these benefits are coming with near-zero liquidity risk
                    and principal risk.
                  </p>
                </div>

                <div className="w-full  ">
                  <img
                    src={carpBussVec}
                    className="absolute sm:top-[10vh] left-4 w-20 sm:w-40 -top-[4vh]"
                    alt="Business Vector"
                  />
                  <img
                    src={carVecBar}
                    className="absolute top-[64vh] w-20 sm:w-36 hidden sm:block left-4"
                    alt="Bar Vector"
                  />
                  <img
                    src={vector}
                    className="absolute top-[74vh] w-20 sm:w-30 hidden sm:block right-8"
                    alt="vector1"
                  />
                </div>
              </div>
            </div>

            <div class="scroll-container sm:mx-auto sm:w-[60%]  ">
              {/* <div className="scrollmenu px-3 mx-auto flex"> */}
              <h1 className="font-bold sm:text-3xl text-xl capitalize  py-3  text-center text-[#6C297C]">
                TAS Instruments{" "}
              </h1>
              <img
                src={carpTas}
                alt="tas"
                className="object-cover h-[50vh] sm:w-full  sm:h-auto flex-none max-w-none"
              />
              {/* </div>s */}
            </div>
            <div className="sm:w-[70%] w-[80%] mx-auto py-14  ">
              <h1 className="font-bold sm:text-3xl text-lg text-center sm:py-2 sm:text-start text-[#6C297C]">
                Case Study
              </h1>
              <p className="sm:font-medium font-bold sm:leading-relaxed leading-loose text-center sm:text-start   tracking-wide text-sm sm:text-[15px]">
                An insurance marketplace company want to accumulate some
                reserves that would fulfill capital requirement stimulated by
                IRDA for new licences. The company wants to allocate INR 50
                crore from profits for the same with the following conditions:-
              </p>
              <ul className="list-disc my-6 sm:leading-loose leading-10 px-4 text-sm sm:text-[15px] text-gray-600">
                <li>High investments grade investments.</li>
                <li>Investment horizon of 2 years.</li>
                <li>No risk of principal loss.</li>
                <li>
                  Option for pre-mature withdrawal (up to 75% portfolio) without
                  penalties
                </li>
              </ul>
            </div>

            <div className="sm:w-[70%] w-full px-3 mx-auto">
              <img
                src={carpChart}
                className="object-cover w-full h-[40vh] sm:h-[auto]"
                alt="chart"
              />
            </div>
          </div>
          <PartnerSection />
          <div className=" justify-center items-center hidden sm:flex bg-body-color pb-10">
            <Link to="/white-label">
              <button className="uppercase bg-buttoncolor text-white font-medium text-sm tracking-wide px-8 py-2.5 rounded-full shadow-md shadow-buttoncolor">
                Lets talk
              </button>
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default CaseStudySection2;
