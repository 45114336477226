import { BrowserRouter, Routes, Route } from "react-router-dom";

import Carporate from "./component/screen/corporate/Index";
import Dashboard from "./component/dashboard/index";
import Header from "./component/header";
import BackToTop from "./component/backToTop";
import Footer from "./component/footer";
import WhiteLabel from "./component/screen/whiteLabel/Index";
import Treasury from "./component/screen/corporate/Treasury";
import AboutUs from "./component/screen/corporate/AboutUs";
import { useRef } from "react";
function App() {
  const resultRef = useRef(null);
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route
            exact
            path="/innovative-reward-program"
            element={<Carporate />}
          />
          <Route exact path="/Treasury" element={<Treasury />} />
          <Route exact path="/white-label" element={<WhiteLabel />} />
          <Route exact path="/about-us" element={<AboutUs />} />
        </Routes>

        <BackToTop />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
