import React, { useEffect } from "react";
import Header from "../header";
import Footer from "../footer";
import SeeUs from "../seeUs";
import Advisory from "../advisory";
import AccountSection from "../accountSection";
import DashSection from "../dashSection";

const Dashboard = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="bg-body-color">
      {/* <Header /> */}
      <DashSection />
      <AccountSection />
      <Advisory />
      <SeeUs />
    </div>
  );
};

export default Dashboard;
