import React from "react";
import dsp from "../../../asset/dsp.png";
import edelweiss from "../../../asset/edelweiss.png";
import hdfc from "../../../asset/hdfc.png";
import icici from "../../../asset/icici.png";
import oswal from "../../../asset/oswal.png";
import unnati from "../../../asset/unnati.png";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PartnerSection = () => {
  const funds = [dsp, edelweiss, hdfc, icici, oswal, unnati];
  const settings = {
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 4,
    // slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    // variableWidth: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <>
      <div className="w-full pb-16 pt-6 bg-body-color ">
        <h1 className="text-center sm:text-4xl text-2xl mb-2 text-[#6C297C] font-bold">
          Partners
        </h1>

        <div className="w-full rounded-xl py-4   bg-purple-300/20">
          <Slider {...settings}>
            {funds.map((item, index) => (
              <div
                key={index}
                className="px-2 flex items-center justify-center h-16 w-20 "
              >
                <img
                  src={item}
                  alt=""
                  className="object-contain w-full h-full "
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default PartnerSection;
