import React, { forwardRef, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import mask from "../../asset/Mask group.png";
import imgaee from "../../asset/advisory.png";
import mask1 from "../../asset/mask12.png";
import note from "../../asset/note.png";
import { Link, useLocation } from "react-router-dom";
import { tabsFunc } from "../../Redux/TabsSlice";

const Advisory = () => {
  const backgroundImage = `url(${imgaee})`;

  const dispatch = useDispatch();

  const isButtonClicked = useSelector((state) => state.tabsSlice);
  // console.log(isButtonClicked, "isButtonClicked");
  const location = useLocation();
  let currentHash = location.hash;
  const ref = useRef(null);

  useEffect(() => {
    dispatch(tabsFunc({ Corporate: true }));
  }, []);

  useEffect(() => {
    if (location.hash) {
      ref?.current?.scrollIntoView({ behavior: "smooth" });
    }

    //window.scrollTo("#offering");
  }, [currentHash, ref?.current]);

  // const go = () => {
  //   ref.current.scrollIntoView({ behavior: "smooth" });
  // };
  console.log(currentHash, currentHash.length, "currentHash");
  // useEffect(() => {
  //   if (isButtonClicked.scroll) {
  //     const element = document.body.querySelector("#offering");
  //     console.log(element, "element");
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [isButtonClicked]);

  return (
    <div
      style={{ backgroundImage }}
      className="bg-no-repeat bg-cover object-cover bg-center rounded-3xl pt-16 "
      id="offering"
      ref={ref}
    >
      <p className="sm:font-bold font-medium text-white text-2xl sm:text-4xl lg:px-32 text-center sm:text-start sm:ms-10 ">
        Our Offering
      </p>
      <div className="sm:font-bold  text-md font-medium  text-white sm:text-xl flex items-center justify-evenly  px-0 lg:px-44 sm:pt-12  pt-6 border-b border-white">
        <Link to="/#corporate" className="contents">
          <button
            className={`sm:w-2/6 leading-10 scroll-behavior-smooth    ${
              location.hash.length === 0 || currentHash === "#corporate"
                ? "border-b-4 border-white"
                : ""
            }`}
            // onClick={() => dispatch(tabsFunc({ Corporate: true }))}
            id="Corporate"
          >
            Corporate
          </button>
        </Link>

        <Link to="/#retail" className="contents">
          <button
            className={`sm:w-2/6 leading-10 scroll-behavior-smooth  ${
              currentHash === "#retail" ? "border-b-4 border-white" : ""
            }`}
            // onClick={() => dispatch(tabsFunc({ Retail: true }))}
            id="Retail"
          >
            Retail
          </button>
        </Link>

        <Link to="/#white-labels" className="contents">
          <button
            className={`sm:w-2/6 leading-10    scroll-behavior-smooth ${
              currentHash === "#white-labels" ? "border-b-4 border-white" : ""
            }`}
            // onClick={() => dispatch(tabsFunc({ White: true }))}
            id="WhiteLabel"
          >
            White-Label
          </button>
        </Link>
      </div>
      <div className="sm:flex items-center justify-between lg:px-48   ">
        <div className="md:w-[40%]  sm:-mt-14  text-center  ">
          <div className="sm:font-bold text-white text-lg font-medium sm:text-start px-4 sm:px-0   pt-6 sm:pt-0  w-full  sm:text-2xl md:text-3xl pb-6 tracking-wide">
            {currentHash === "#corporate" ? (
              <p>Simple, Smart Investing.</p>
            ) : currentHash === "#retail" ? (
              <p>Invest like the Richest 1%.</p>
            ) : (
              <p>
                Wealth Management, <br /> Elevated.
              </p>
            )}
          </div>

          <p className="text-white    w-full    tracking-wide leading-relaxed sm:text-start text-center px-10 sm:px-0  ">
            {currentHash === "#corporate"
              ? "Elever empowers your company to optimize investing returns on treasury, capital reserves & employee incentive funds while minimizing liquidity and principal loss risks in the investment process."
              : currentHash === "#retail"
              ? "Elever empowers retail investors with HNI-grade strategies, enabling long-term alpha generation through rule based factor portfolios and tactical rebalancing models."
              : "Elever empowers wealth managers and financial institutions through turnkey technology solutions that allow them to scale their wealth practice efficiently."}
          </p>

          <div className="text-center sm:text-start mt-6">
            {currentHash === "#corporate" ? (
              <Link to="/Treasury">
                <button className="uppercase bg-buttoncolor text-white text-sm font-medium px-8 py-2.5  rounded-full shadow-md shadow-buttoncolor">
                  Know more
                </button>
              </Link>
            ) : currentHash === "#retail" ? (
              <a href="https://www.elever.app/" target="_blank">
                <button className="uppercase bg-buttoncolor text-white text-sm font-medium px-8 py-2.5  rounded-full shadow-md shadow-buttoncolor">
                  Know more
                </button>
              </a>
            ) : (
              <Link to="/white-label">
                <button className="uppercase bg-buttoncolor text-white text-sm font-medium px-8 py-2.5  rounded-full shadow-md shadow-buttoncolor">
                  Know more
                </button>
              </Link>
            )}
          </div>
        </div>
        <img
          className="w-[70vh] h-auto mx-auto py-6 sm:py-10 px-14 sm:px-0 transform hover:scale-110 transition-transform duration-300"
          src={
            isButtonClicked.Corporate
              ? mask
              : isButtonClicked.Retail
              ? mask1
              : note
          }
          alt="mask"
        />
      </div>
    </div>
  );
};

export default Advisory;
