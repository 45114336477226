import React from "react";
import { FooterAddress, FooterData, FooterLinks } from "./constants";
import headerLogo from "../../asset/logo.png";
import google from "../../asset/google.png";
import apple from "../../asset/apple.png";
import Social from "../social";
import { Link, NavLink, useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full  bg-light-gray  ">
        <div className=" flex sm:px-44 px-4  py-10    flex-col-reverse justify-between sm:items-start items-center sm:flex-row  ">
          <div className="sm:w-1/2 w-full  ">
            {FooterAddress.map((item) => (
              <div className="my-4  w-[100%]">
                <p className="text-[#484848] font-bold	">{item.title}</p>
                <div className="sm:w-[60%] w-[90%] tracking-wide text-gray-600 ">
                  <p className="text-sm">{item.regNo}</p>
                  <p className="text-sm">{item.regValidity}</p>
                  <p className="text-sm">{item.cin}</p>
                  <p className="text-sm">{item.cinkey}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="sm:w-1/2 w-full  flex flex-col-reverse sm:flex-col ">
            <div className="flex  justify-between gap-6 my-5 px-4 text-[#484848] ">
              <div className="pb-2 ">
                <div
                  className="pb-4 text-sm sm:text-sm sm:font-bold  "
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  <NavLink to="/Treasury">Treasury</NavLink>
                </div>
                <div
                  className="pb-4 text-sm sm:text-sm sm:font-bold  "
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  <NavLink to="/innovative-reward-program">
                    Innovative Reward Program
                  </NavLink>
                </div>
                <div
                  className="pb-4 text-sm sm:text-sm sm:font-bold"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {/* <a href="https://www.elever.app/about-us">ABOUT US</a> */}
                  <NavLink to="/about-us">About Us</NavLink>
                </div>

                <div
                  className="pb-4 text-sm sm:text-sm sm:font-bold cursor-pointer  "
                  onClick={() => navigate("/#retail")}
                >
                  Retail
                </div>
              </div>
              <div className="pb-4  ">
                <div className="pb-4 text-sm sm:text-sm sm:font-bold">
                  <a href="https://www.elever.app/privacy-policy">
                    Privacy Policy
                  </a>
                </div>
                <div className="pb-4 text-sm sm:text-sm sm:font-bold">
                  <a href="https://www.elever.app/terms-and-conditions">
                    Terms & Conditions
                  </a>
                </div>

                <div
                  className="pb-4 text-sm sm:text-sm sm:font-bold cursor-pointer  "
                  onClick={() => navigate("/#corporate")}
                >
                  Corporate
                </div>

                <div
                  className="pb-4 text-sm sm:text-sm sm:font-bold  cursor-pointer "
                  onClick={() => navigate("/#white-labels")}
                >
                  white-Label
                </div>
              </div>

              <img
                className="hidden sm:block w-[100px] h-[100px]"
                src={headerLogo}
                alt="newLogo"
              />
            </div>
            <div>
              <div className="text-center sm:text-left">
                <div className="flex justify-center">
                  <img
                    className="sm:hidden pb-8 "
                    src={headerLogo}
                    alt="newLogo"
                  />
                </div>
                <p className="pb-4">Download our app to start Investing</p>
                <div className="flex pb-4 justify-center items-center sm:justify-start sm:items-left">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.elever.invest"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={google} alt="google" className="sm:w-2/3 mr-2" />
                  </a>

                  <a
                    href="https://apps.apple.com/in/app/elever-personalised-investing/id1627407067"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={apple} alt="apple" className="sm:w-2/3 mr-2" />
                  </a>
                </div>
              </div>
              <p className=" sm:text-left px-4 text-start  sm:px-0 text-gray-600 text-sm ">
                Elever Investment Adviser Private Limited ("Elever") is a SEBI
                registered Investment Adviser (Registration No. INA200016102).
                Investment in securities market are subject to market risks.
                Read all the related documents carefully before investing.
                Registration granted by SEBI, membership of BASL and
                certification from NISM in no way guarantee performance of the
                intermediary or provide any assurance of returns to investors.
              </p>
            </div>
          </div>
        </div>
        <Social />
      </div>
    </>
  );
};

export default Footer;
