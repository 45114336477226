import React, { useState } from "react";
import headerLogo from "../../asset/logo.png";
import hamburger from "../../asset/hamburger.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { tabsFunc } from "../../Redux/TabsSlice";
const Header = () => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const go = (name) => {
    if (name === "corporate") {
      console.log("corporate clicked");
      dispatch(tabsFunc({ Corporate: true, scroll: true }));
      setMenu(false);
      navigate("/#corporate");
    } else if (name === "retail") {
      console.log("retail clicked");
      dispatch(tabsFunc({ Retail: true, scroll: true }));
      setMenu(false);
      navigate("/#retail");
    } else {
      console.log("white clicked");
      dispatch(tabsFunc({ White: true, scroll: true }));
      setMenu(false);
      navigate("/#white-labels");
    }
  };

  return (
    <>
      <header className="p-4 sm:pl-8 md:pl-28 lg:pl-32 z-[9999] relative ">
        <div className="flex items-center justify-between font-medium opacity-70">
          <div className="text-black text-lg font-semibold  ">
            <NavLink to="/">
              <img src={headerLogo} alt="logo" className="w-12" />
            </NavLink>
          </div>

          <ul className="hidden md:flex space-x-14 pr-32">
            <li>
              <NavLink to="/about-us">About Us</NavLink>
              {/* About Us */}
            </li>
            <li onClick={() => go("corporate")} className="cursor-pointer">
              {/* <Link to="/#corporate">Corporate</Link> */}
              Corporate
            </li>
            <li onClick={() => go("retail")} className="cursor-pointer">
              {/* <Link to="/#retail">Retail</Link> */}
              Retail
            </li>
            <li onClick={() => go("white")} className="cursor-pointer">
              {/* <Link to="/#white-labels "> White-Label</Link> */}
              White-Label
            </li>
          </ul>

          <div
            className="sm:hidden flex  rounded-lg "
            style={{ filter: "brightness(0.1)" }}
          >
            {!menu ? (
              <>
                {" "}
                <AiOutlineMenu onClick={() => setMenu(!menu)} size={30} />
              </>
            ) : (
              <>
                <AiOutlineClose onClick={() => setMenu(!menu)} size={30} />
              </>
            )}
          </div>
        </div>
      </header>
      {menu ? (
        <div className="absolute z-50 w-screen flex justify-center items-center sm:hidden  h-screen top-0 bg-slate-950/70  duration-70000 animate-fade-in ">
          <ul className="flex flex-col gap-4 py-8 p-3 text-center  bg-white rounded-lg  text-[#d900ffba] w-[80%] mx-auto  ">
            <li className="w-full  px-6  ">
              <NavLink to="/" onClick={() => setMenu(false)}>
                Home
              </NavLink>
            </li>
            <li className="w-full  px-6  ">
              <NavLink to="/about-us" onClick={() => setMenu(false)}>
                About Us
              </NavLink>
            </li>

            <li onClick={() => go("corporate")} className="cursor-pointer">
              Corporate
            </li>

            <li onClick={() => go("retail")} className="cursor-pointer">
              Retail
            </li>
            <li onClick={() => go("white")} className="cursor-pointer">
              White-Label
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Header;
