import React from "react";
import { SeeUsData } from "./constants";
import frame from "../../asset/Frame (2).png";
import group from "../../asset/Group.png";

const SeeUs = () => {
  return (
    <>
      <div className=" w-full bg-body-color pb-20">
        <div className="sm:w-full  sm:flex sm:justify-between md:items-center hidden  ">
          <img className="relative top-16 left-0" src={frame} alt="frame" />
          <img className="relative top-20 right-10" src={group} alt="group" />
        </div>
        <div className="xl:mx-32  lg:mx-32 md:mx-32 sm:mx-32 mt-24 sm:mt-7  sm:px-14 px-4 py-6 bg-ligth-blue mx-4  rounded-md ">
          <p className="text-black font-bold  text-2xl sm:text-3xl py-6 sm:text-start opacity-60 tracking-wide capitalize  text-center">
            What Sets Us Apart
          </p>
          {SeeUsData.map((item, index) => (
            <div
              className={`flex  mb-10   flex-col  text-center w-full sm:text-left sm:flex-row  ${
                index === 2 ? "" : "border-b-2 border-gray-400"
              }`}
            >
              <div className="pt-4      flex justify-center items-center sm:block  ">
                <img src={item.image} alt={item} />
              </div>
              <div className="sm:w-[80%]  sm:ps-4 w-full sm:h-[130px]  my-4 sm:my-0   ">
                <p className="text-black font-bold text-md mb-2 sm:mb-4	xl:text-lg md:text-lg opacity-60">
                  {item.title}
                </p>
                <p className="text-sm  md:text-sm  text-gray-600	tracking-wide">
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SeeUs;
