import fund from "../../asset/image11.png";
import axis from "../../asset/image9.png";
import grow from "../../asset/image 7.png";
import paisa from "../../asset/image 5.png";
// import edelweiss from "../../asset/image12.png";
import edelweiss from "../../asset/image12.webp";
import IIFL from "../../asset/image 6.png";
import dhan from "../../asset/image 8.png";
import hdfc from "../../asset/image 10.png";
import jm from "../../asset/jm.png";
import affline from "../../asset/affine.png";
import easebuzz from "../../asset/easebuzz.png";
import dc from "../../asset/DC.png";
import newVertical from "../../asset/newVertical.png";
import prime from "../../asset/prime.png";

export const accountSection = [
  {
    title: "J m ",
    image: jm,
  },
  {
    title: "affline",
    image: affline,
  },
  {
    title: "easebuzz",
    image: easebuzz,
  },
  {
    title: "DC",
    image: dc,
  },
  {
    title: "newVertical",
    image: newVertical,
  },
  {
    title: "prime",
    image: prime,
  },
];
