import React from "react";
import image33 from "../../asset/image33.png";
import image34 from "../../asset/image34.png";
import image1350 from "../../asset/Frame1350.png";
import gotIt from "../../asset/gotIt.png";
import frame from "../../asset/Frame.png";
import frame1 from "../../asset/Frame 1.png";
import { Link } from "react-router-dom";

const DashSection = () => {
  return (
    <>
      <div className=" w-full">
        <div className="flex">
          <div className="sm:w-6/12 sm:pl-8 md:pl-28 lg:pl-32 sm:pt-5 sm:mt-14 w-full mt-32 sm:text-left text-center">
            <p className="font-bold text-xl mt-20 sm:mt-0   sm:text-5xl pb-5 opacity-60 capitalize sm:leading-[65px]  ">
              {" "}
              Empowering wealth <br /> with tech
            </p>{" "}
            <p className="w-full sm:w-[30rem] leading-loose text-center   sm:text-start px-9 sm:px-0 sm:leading sm:text-xl pb-6 text-light-text">
              Elever revolutionizes investing for all, offering tech-enabled
              investment advisory for retail & corporate investors and scalable
              robo-solutions for wealth managers & financial institutions.
            </p>{" "}
            {/* <a
              href="https://www.elever.app/about-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="uppercase bg-buttoncolor text-white text-sm font-medium px-8 py-2.5 rounded-full shadow-md shadow-buttoncolor">
                Get to know us
              </button>
            </a> */}
            <Link to="/white-label">
              <button className="uppercase bg-buttoncolor text-white text-sm font-medium px-8 py-2.5 rounded-full shadow-md shadow-buttoncolor">
                Lets Talk
              </button>
            </Link>
          </div>
          <div className="absolute top-[0px] w-[84%] sm:w-6/12   right-[0] z-[0]  ">
            <img className="absolute" src={image34} alt="backImage" />
            <div className="relative">
              <img className="absolute" src={image33} alt="backImage" />
              <img
                className="relative sm:pt-[124px] pt-4   sm:w-[70%] w-[65%] left-20 sm:left-36 "
                src={gotIt}
                alt="banner"
              />
            </div>
          </div>
        </div>
        <div className="md:flex md:justify-between relative    sm:px-10">
          <img
            className="absolute object-cover sm:top-[12vh] sm:left-11  w-20 left-0 top-0  sm:w-[180px]  "
            src={frame}
            alt="frame"
          />
          <img
            className="absolute  -top-[35vh] sm:top-[35vh] right-0 sm:right-[15.5vh] object-contain  sm:w-[130px] w-20 "
            src={frame1}
            alt="frame"
          />
        </div>
      </div>
    </>
  );
};

export default DashSection;
