import React from "react";
import { accountSection } from "./constants";
import ellipse from "../../asset/Ellipse 11.png";
import elipse from "../../asset/Ellipse 10.png";

const AccountSection = () => {
  return (
    <>
      <div className="w-[90%]   sm:mt-[35vh] mt-[13vh]  mx-auto  sm:w-[60%]   grid sm:grid-cols-3 sm:gap-3 grid-cols-2 gap-8 md:grid-cols-3  md:gap-5  justify-center    place-items-center z-[999] relative">
        {accountSection.map((item) => (
          <div className="flex justify-center items-center flex-col  ">
            <img
              src={item.image}
              alt="sectionimage"
              className={`object-cover  ${
                item.title === "newVertical" ? "invert" : ""
              }
              ${item.title === "easebuzz" ? "w-[30vh]" : ""}
              `}
            />
            {/* <p className="pt-3">{item.title}</p> */}
          </div>
        ))}
      </div>
      <div className=" w-full flex justify-between items-center ">
        <img
          className=" w-10 sm:w-[9vh] relative  sm:bottom-[15vh] bottom-[19vh] "
          src={elipse}
          alt="elipse"
        />
        <img
          className="w-10 sm:w-[9vh] relative sm:bottom-[15vh] bottom-[19vh]"
          src={ellipse}
          alt="ellipse"
        />
      </div>
    </>
  );
};

export default AccountSection;
