import React from "react";
import coinSpin from "../../../asset/coin-spin.gif";
import person from "../../../asset/person_icon.jpg";
import rupee from "../../../asset/rupee_icon.jpg";
import setting from "../../../asset/setting_icon.jpg";
import qu1 from "../../../asset/quoteIcon1.svg";
import qu2 from "../../../asset/quoteIcon2.svg";
import linkd from "../../../asset/linked.svg";
import ansul from "../../../asset/anshul.jpg";
import ram from "../../../asset/ram.png";
import santosh from "../../../asset/santosh-400px.png";
import karan from "../../../asset/karan.jpg";
import rk from "../../../asset/RKang.jpg";
import ganesh from "../../../asset/Ganesh-Gopalkrishnan.png";

const AboutUs = () => {
  return (
    <>
      <div className="w-full  bg-body-color  ">
        <div className="  sm:flex items-center sm:justify-between sm:w-[85%] w-full  mx-auto py-16 ">
          <div className=" order-last ">
            <div className="flex justify-center items-center">
              <img
                src={coinSpin}
                className="w-40 object-cover "
                alt="coinspin"
              />
            </div>
          </div>
          <h1 className="sm:text-4xl  sm:inline-flex  text-xl hidden  font-bold text-[#484848] sm:text-start sm:ps-4 ">
            Every Great Solution Begins with a Problem Experienced <br /> First
            Hand
          </h1>
          <h1 className="sm:text-4xl text-2xl block  sm:hidden font-bold text-[#484848] text-center sm:text-start sm:ps-4 ">
            {" "}
            Every Great Solution <br /> Begins with a Problem <br />
            Experienced First Hand
          </h1>
        </div>
      </div>

      <div className="w-[80%] mx-auto py-20">
        <p className="text-center text-sm sm:text-lg">
          As customers looking for ways to improve our personal finance, and
          achieve our <br /> financial goals, each of us felt a lack of
          guidance, tools, and platforms. So, we set out to <br /> solve this
          very problem. <br />
          <br /> We designed Elever to have a human-centred soul and an
          artificial intelligence driven <br /> mind. In other words, easy for
          people at any level of financial expertise to interact with it <br />,
          and high-tech enough to build, execute, and manage a fully customised
          financial <br />
          strategy to help everyone achieve their dreams.
        </p>
      </div>

      <div className="sm:w-[70%] w-full  mx-auto p-10">
        <div className="grid grid-cols-3 gap-6">
          <div className="text-center col-span-full sm:col-span-1">
            <div className="w-full text-center  flex justify-center items-center">
              <img src={person} className=" object-cover w-20 " alt="person" />
            </div>
            <p className="text-sm sm:text-lg">
              Entirely customised to every <br /> individual & 100% unbiased{" "}
              <br />
              advisory
            </p>
          </div>
          <div className="text-center col-span-full sm:col-span-1">
            <div className="w-full text-center  flex justify-center items-center">
              <img src={rupee} className=" object-cover w-20 " alt="person" />
            </div>
            <p className="text-sm sm:text-lg">
              Aimed to be affordable- big <br /> investor toolkit, now for{" "}
              <br /> everyone
            </p>
          </div>
          <div className="text-center col-span-full sm:col-span-1 pb-8 sm:pb-0">
            <div className="w-full text-center  flex justify-center items-center">
              <img src={setting} className=" object-cover w-20 " alt="person" />
            </div>
            <p className="text-sm sm:text-lg">
              End-to-end financial <br /> advisory, in just a few clicks
            </p>
          </div>
        </div>
      </div>

      <div className=" bg-[#eee5ff] py-28 sm:mt-6">
        <div className="sm:w-[55%] w-full mx-auto flex">
          <img
            src={qu1}
            className=" sm:-mt-20 -mt-[8.5rem] px-2 sm:w-14 w-10"
            alt=""
          />
          <p
            class=" text-center leading-relaxed sm:tracking-wider text-[18px] sm:text-[22px]  "
            style={{ fontWeight: "200" }}
          >
            We are our product’s first customers. After all, there’s no greater{" "}
            <br />
            conviction in an idea than you paying for it yourself
          </p>
          <img
            src={qu2}
            className="sm:-mb-20 -mb-[8.5rem] px-2 sm:w-14 w-10"
            alt=""
          />
        </div>
      </div>

      <div className="text-center w-full py-10 ">
        <h1 className="sm:text-3xl text-2xl text-[#6f0080] py-2 sm:pt-5 uppercase">
          Team
        </h1>
        <p className="text-center tracking-wide sm:text-xl text-sm  px-4 hidden sm:block pb-4 sm:pb-10 ">
          We are a team with diverse skillsets - financial experts, product{" "}
          <br />
          engineers, and marketing specialists. All bound by a singular passion{" "}
          <br />
          to level the playing field for managing money.
        </p>
        <p className="text-center tracking-wide sm:text-xl text-sm  px-4 block sm:hidden pb-10 ">
          We are a team with diverse skillsets - financial experts, product{" "}
          engineers, and marketing specialists. All bound by a singular passion{" "}
          to level the playing field for managing money.
        </p>
      </div>

      <div className="sm:w-[60%] w-full px-5  mx-auto">
        <div className="grid grid-cols-2 gap-10 sm:gap-20  sm:py-10">
          <div className="col-span-full sm:col-span-1">
            <h1 className="font-bold text-xl mb-0 text-[#484848]">
              ANSHUL SHARAN
            </h1>
            <hr />
            <div className="flex justify-between items-center py-2">
              <p>Co-founder, CEO</p>
              <a
                href="https://www.linkedin.com/in/anshulsharan/"
                target="_blank"
                aria-label="santosh"
              >
                <img
                  src={linkd}
                  className="w-7 rounded border-dashed border-2 p-1  object-cover"
                  alt=""
                />
              </a>
            </div>
            <img src={ansul} className="object-cover h-2/2 w-full" alt="" />
            <div className="my-4  border-b-2 border-gray-700" />
            <p className="text-start  tracking-wider">
              IIT/IIM alumnus, with 14+ years experience in investment &
              finance. The brain behind our product’s heart.
            </p>
          </div>
          <div className="col-span-full sm:col-span-1">
            <h1 className="font-bold text-xl mb-0 text-[#484848]">
              RAM SUBRAMANIAM
            </h1>
            <hr />
            <div className="flex justify-between items-center py-2">
              <p>Co-founder, CTO</p>
              <a
                href="https://www.linkedin.com/in/rasubramaniam/"
                target="_blank"
              >
                <img
                  src={linkd}
                  className="w-7 rounded border-dashed border-2 p-1  object-cover"
                  alt=""
                />
              </a>
            </div>
            <img src={ram} className="object-cover h-2/2 w-full" alt="" />
            <div className="my-4  border-b-2 border-gray-700" />
            <p className="text-start  tracking-wider">
              Ex-global tech lead for Visa, with 22+ years experience, the human
              brain behind our product’s artificial one.
            </p>
          </div>
          <div className="col-span-full sm:col-span-1">
            <h1 className="font-bold text-xl mb-0 text-[#484848]">
              SANTOSH AIYAR
            </h1>
            <hr />
            <div className="flex justify-between items-center py-2">
              <p>Co-founder, CMO</p>
              <a
                href="https://www.linkedin.com/in/i-am-santosh-r/"
                target="_blank"
              >
                <img
                  src={linkd}
                  className="w-7 rounded border-dashed border-2 p-1  object-cover"
                  alt=""
                />
              </a>
            </div>
            <img src={santosh} className="object-cover h-2/2 w-full" alt="" />
            <div className="my-4  border-b-2 border-gray-700" />
            <p className="text-start  tracking-wider">
              17+ years in consumer research, marketing & communication design -
              the brain behind our brand’s voice.
            </p>
          </div>

          <div className="col-span-full sm:col-span-1">
            <h1 className="font-bold text-xl mb-0 text-[#484848]">
              KARAN, CFA
            </h1>
            <hr />
            <div className="flex justify-between items-center py-2">
              <p>Founding Member, CIO</p>
              <a
                href="https://www.linkedin.com/in/karan-aggarwal-cfa-890045ba/"
                target="_blank"
              >
                <img
                  src={linkd}
                  className="w-7 rounded border-dashed border-2 p-1  object-cover"
                  alt=""
                />
              </a>
            </div>
            <img src={karan} className="object-cover h-2/2 w-full " alt="" />
            <div className="my-4  border-b-2 border-gray-700" />
            <p className="text-start  tracking-wider">
              MDI alumnus, with 10+ years experience in designing investment
              strategies. More than USD 5 Bn tracking on his work in US and
              Europe.
            </p>
          </div>
        </div>
      </div>

      <div className="text-center w-full pt-10 sm:py-10">
        <h1 className="sm:text-3xl text-2xl text-[#6f0080]  sm:pt-6 uppercase">
          ADVISORS
        </h1>
        <p className="text-center tracking-wide sm:text-xl text-[16px] px-6 pb-10">
          Advisors can help shape a company with their priceless experience, but{" "}
          <br />
          equally important, is to have a vision-fit with the founders. We are
          lucky <br /> enough to have found both with ours.
        </p>
      </div>

      <div className="sm:w-[50%] w-full px-5  mb-6 mx-auto">
        <div className="grid grid-cols-2 gap-6 py-10">
          <div className="col-span-full sm:col-span-1 ">
            <div className="flex justify-center items-center ">
              <img
                src={rk}
                className="object-cover h-2/2 w-40 rounded-full"
                alt=""
              />
            </div>
            <h1 className="font-bold text-center text-sm sm:text-sm  text-[#484848]">
              RICHARD KANG
            </h1>
            <p className="text-center pb-2 text-[13px]">
              MANAGING DIRECTOR <br />
              SIGMA ANALYSIS
            </p>
            <div className="flex justify-center items-center">
              <a
                href="https://www.linkedin.com/in/rkang1/"
                target="_blank"
                aria-label="rk"
              >
                <img
                  src={linkd}
                  className="w-7 rounded border-dashed border-2 p-1  object-cover"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="col-span-full sm:col-span-1 ">
            <div className="flex justify-center items-center ">
              <img
                src={ganesh}
                className="object-cover h-2/2 w-40 rounded-full"
                alt=""
              />
            </div>
            <h1 className="font-bold text-center text-sm sm:text-sm  text-[#484848]">
              GANESH GOPALAKRISHNAN
            </h1>
            <p className="text-center pb-2 text-[13px]  ">
              HEAD OF ENGINEERING <br />
              NETFLIX, US
            </p>
            <div className="flex justify-center items-center">
              <a
                href="https://www.linkedin.com/in/1ganesh/"
                target="_blank"
                aria-label="rk"
              >
                <img
                  src={linkd}
                  className="w-7 rounded border-dashed border-2 p-1  object-cover"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
