import React, { useEffect, useState } from "react";
import carp1 from "../../../asset/carp1.png";
import carp2 from "../../../asset/carp2.png";
import MoneyBox from "../../../asset/moneyBox.png";
import giftBox from "../../../asset/giftBox.png";
import money from "../../../asset/money.png";
import carpVec1 from "../../../asset/carpVec1.png";
import carpVec2 from "../../../asset/carpVec2.png";
import carpVect3 from "../../../asset/carpVact3.png";
import CaseStudySection2 from "./CaseStudySection2";

import { Link } from "react-router-dom";
const Treasury = () => {
  const [select, setSelect] = useState(true);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div className="  w-full bg-body-color  h-screen sm:relative hidden sm:block">
        <div className=" sm:flex justify-between items-center  relative   ">
          <div className="  w-full   sm:pt-4   relative  ">
            <div className="flex justify-start items-center  ">
              <div className=" text-start  ps-[20vh]">
                {!select ? (
                  <>
                    <div>
                      {" "}
                      <h1 className="text-4xl capitalize font-bold leading-relaxed tracking-wide  ">
                        <i>
                          {" "}
                          <span className="opacity-90 text-[rebeccapurple]">
                            Innovative Reward
                          </span>{" "}
                          Program to <br /> Retain &nbsp;
                          <span className="opacity-90 text-[rebeccapurple]">
                            Key Talent
                          </span>
                        </i>
                      </h1>
                      <div className="">
                        <p className="text-gray-500 font-[400] tracking-wide text-xl">
                          Build a long-term corpus as a “Retention Bonus” for{" "}
                          <br />
                          employees while achieving sizable replacement cost{" "}
                          <br /> saving per employee.
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="">
                      <h1 className="text-4xl capitalize font-bold leading-relaxed tracking-wide text-gray-600/90  pt-3">
                        {" "}
                        Prioritize, Optimize, <br />
                        <span className="opacity-90 text-[rebeccapurple]">
                          Maximize.
                        </span>
                      </h1>
                      <div className="py-2">
                        <p className="text-gray-500 font-[400] tracking-wide text-xl ">
                          Improve return potential on your operating funds &
                          reserves <br /> with zero principal loss & liqudity
                          risk, providing an additional <br /> cushion for
                          finance managers and CFO office.
                        </p>
                      </div>
                    </div>
                  </>
                )}

                <Link to="/Treasury">
                  <div
                    className={` cursor-pointer  w-[50%] border-gray-300 my-4 flex items-center p-3  rounded-lg ${
                      select
                        ? "bg-[#1B8DB5] text-white  "
                        : " bg-white border-2 opacity-60 "
                    }`}
                    onClick={() => setSelect(true)}
                  >
                    <div className=" border bg-sky-200/20  rounded-lg p-1">
                      <img
                        src={MoneyBox}
                        className="w-16 h-12 object-contain"
                        alt=""
                      />
                    </div>
                    <p className="text-lg font-medium ps-4 ">
                      Treasury Advisory
                    </p>
                  </div>
                </Link>
                <Link to="/innovative-reward-program">
                  <div
                    className={` w-[80%] cursor-pointer  my-4 flex items-center p-3 rounded-lg  ${
                      !select
                        ? "bg-[#1B8DB5] text-white"
                        : " bg-white text-black border-gray-300 border-2 opacity-60"
                    }`}
                    onClick={() => setSelect(false)}
                  >
                    <div className=" border bg-sky-200/20 rounded-lg p-1">
                      <img
                        src={giftBox}
                        className="w-16 h-12 object-contain"
                        alt=""
                      />
                    </div>
                    <p className="text-lg font-medium ps-4  ">
                      Innovative Reward Program
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="absolute  right-0 z-0 sm:top-3 order-1  ">
            <img
              className="absolute sm:w-96 sm:right-4 sm:top-32 z-50 w-[34vh]  top-[31vh] right-4"
              src={money}
              alt="backImage"
            />
            <div className="relative">
              <img
                className="absolute sm:w-72 sm:top-24 right-0 z-40 w-[30vh] top-[8vh]  "
                src={carp2}
                alt="backImage"
              />
              <img
                className="absolute sm:w-[90%]   right-0 sm:top-10  top-[5vh] w-[34vh]  z-30  "
                src={carp2}
                alt="backImage"
              />
              <img
                className="relative sm:w-96 w-80  z-20  "
                src={carp1}
                alt="banner"
              />
            </div>
          </div>
        </div>

        {/* ---------------vector===================== */}
        <div className="absolute sm:top-24">
          <img
            src={carpVec1}
            className="sm:bottom-14 absolute hidden sm:block"
            alt="vector1"
          />
          <img
            src={carpVec2}
            className="sm:top-[45vh] w-24 left-4 top-[60vh]  relative"
            alt="vector1"
          />
        </div>
      </div>

      {/* -----------in android screen-------------------------- */}
      <div className="block sm:hidden w-full">
        <div className="w-full h-[42vh] ">
          <div className="absolute  right-0 z-0  top-0 sm:top-3 order-1  ">
            <img
              className="absolute z-50 w-[35vh]  top-[9vh] right-[3rem]"
              src={money}
              alt="backImage"
            />
            <div className="relative ">
              <img
                className="absolute right-0 z-40 w-[25vh] bottom-[25vh]  "
                src={carp2}
                alt="backImage"
              />
              <img
                className="absolute    right-0   bottom-[21vh] w-[30vh]  z-30  "
                src={carp2}
                alt="backImage"
              />
              <img
                className="relative  w-[35vh] bottom-[16vh] z-20 right-0  "
                src={carp1}
                alt="banner"
              />
            </div>
          </div>
        </div>

        <div className="  w-full text-center  relative ">
          <div className="flex justify-center items-center">
            <div className="px-3 ">
              <div className="">
                {!select ? (
                  <>
                    <h1 className="text-xl capitalize font-bold leading-relaxed tracking-wide text-gray-600/90 ">
                      <i>
                        <span className="opacity-90 text-[rebeccapurple]">
                          Innovative Reward
                        </span>{" "}
                        Program <br /> To Retain &nbsp;
                        <span className="opacity-90 text-[rebeccapurple]">
                          Key Talent
                        </span>
                      </i>
                    </h1>
                    <div className=" py-3 ">
                      <p className="text-gray-500 font-[400] tracking-wide text-sm text-center ">
                        Build a long-term corpus as a “Retention Bonus” for{" "}
                        <br />
                        employees while achieving sizable replacement cost{" "}
                        <br /> saving per employee for the corporate.
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <h1 className="text-xl capitalize font-bold leading-relaxed tracking-wide text-gray-600/90 pt-3 ">
                      Prioritize, Optimize, Maximize
                    </h1>

                    <div className="py-3">
                      <p className="text-gray-500 font-[400] tracking-wide text-sm text-center ">
                        Improve return potential on your operating funds &
                        reserves with zero principal loss & liqudity risk,
                        providing an additional cushion for finance managers and
                        CFO office.
                      </p>
                    </div>
                  </>
                )}
              </div>

              <div className="flex justify-center items-center pt-10 pb-8 gap-6 ">
                <div
                  className={` w-[50%] border-gray-300 my-4  p-3 rounded-lg ${
                    select
                      ? "  bg-[#1B8DB5] text-white my-4"
                      : "bg-white border-2 "
                  }`}
                  onClick={() => setSelect(true)}
                >
                  <div className="   w-16 h-12 p-3 border mx-auto  flex justify-center items-center   rounded-lg ">
                    <img
                      src={MoneyBox}
                      className="w-16 h-12 object-contain border "
                      alt=""
                    />
                  </div>
                  <p className="text-xs font-medium ps-4 mt-2 ">
                    Treasury <br />
                    Advisory
                  </p>
                </div>
                <div
                  className={`w-[50%] my-4   p-3 rounded-lg ${
                    !select ? "bg-[#1B8DB5] text-white" : "bg-white border-2 "
                  }`}
                  onClick={() => setSelect(false)}
                >
                  <div className="   w-16 h-12 p-3 border mx-auto bg-sky-200/20 flex justify-center items-center   rounded-lg ">
                    <img
                      src={giftBox}
                      className="w-16 h-12 object-contain border "
                      alt=""
                    />
                  </div>
                  <p className="text-xs font-medium ps-4 mt-2 ">
                    Innovative Reward Program
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute z-10 bottom-[21vh] ">
          <img src={carpVect3} className="  sm:block" alt="vector1" />
        </div>
      </div>

      <CaseStudySection2 select={select} />
    </>
  );
};

export default Treasury;
